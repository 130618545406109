import React from 'react'
import Text from '@component/Text/Text'
import RightArrow from '@svg/event/common/ic_leaderboard_arrow.svg'
import {IEventLeague} from '@api/rank/league/getLeagueDetail'

export type ColumnListType = {
    id: string
    image?: React.ReactNode
    title?: string
    desc?: string
    linkText?: string
    link?: string
}

interface IProps {
    className?: string
    eventInitState: IEventLeague
    title?: string
    titleClassName?: string
    columnList?: ColumnListType[]
    containerClassName?: string
    listClassName?: string
}

const LeagueColumnContainer: React.FC<IProps> = ({
    className,
    eventInitState,
    title,
    listClassName,
    titleClassName,
    columnList,
    containerClassName,
}) => {
    const {common} = eventInitState

    return (
        <div className={className}>
            <div>
                <Text className={`text-h1 text-center sm:text-h2 ${titleClassName}`}>{title}</Text>
            </div>
            <div className={`${containerClassName}`}>
                {columnList?.map(list => (
                    <div key={list?.id} className={`${listClassName}`}>
                        <div>
                            <div>{list?.image}</div>
                            <Text className={'pt-[20px] text-h3 sm:text-h4'}>{list?.title}</Text>
                            <Text className={`pt-[10px] sm:text-body2 md:min-h-[100%] ${common.subTextColor}`}>
                                {list?.desc}
                            </Text>
                        </div>
                        <div
                            className={'py-[10px] mt-[10px] flex items-center cursor-pointer'}
                            onClick={() => {
                                window.open(list?.link)
                            }}>
                            <Text className={'underline text-body2'}>{list?.linkText}</Text>
                            <RightArrow fill={eventInitState.common.baseColor} />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default LeagueColumnContainer
