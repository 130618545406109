import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'next-i18next'
import Text from '@component/Text/Text'
import UserThumbnail from '@component/images/UserThumbnail'
import {IUserRank} from '@api/rank/rank'
import WizImage from '@component/images/WizImage'
import ic_medal_gold from '@image/event/common/prize_medal_gold.png'
import ic_medal_silver from '@image/event/common/prize_medal_silver.png'
import ic_medal_bronze from '@image/event/common/prize_medal_bronze.png'
import ic_medal_default from '@image/event/common/prize_medal_default.png'
import {IEventPrizePool} from '@api/rank/league/league'
import useFormatNumber from '@hook/useFormatNumber'
import {useRouter} from 'next/router'
import NoRankUserIcon from '@svg/event/common/ic_rank_no_user.svg'
import WizButton from '@component/buttons/WizButton'
import {IEventLeague} from '@api/rank/league/getLeagueDetail'
import useNavigation from '@hook/useNavigation'
import {createLog} from '@util/logs'

interface IProps {
    className?: string
    prizeList?: IEventPrizePool
    onClickJoin?: () => void
    eventInitState: IEventLeague
    isUseComment?: boolean
    userRankList: IUserRank[]
}

const LeagueRankTable: React.FC<IProps> = ({
    className,
    prizeList,
    onClickJoin,
    eventInitState,
    isUseComment = true,
    userRankList,
}) => {
    const {t} = useTranslation()
    const {rank, id, common, currency} = eventInitState
    const router = useRouter()
    const {displayPercent} = useFormatNumber()
    const {goUserPortfolio} = useNavigation()
    const [commentState, setCommentState] = useState(0)

    const getRankThumbnailStyle = useCallback((idx: number) => {
        if (idx === 0) {
            return `border-[#FFCB44]`
        } else if (idx === 1) {
            return 'border-[#DEDEDE]'
        } else if (idx === 2) {
            return 'border-[#FF9839]'
        } else {
            return 'border-transparent'
        }
    }, [])

    const randomCommentNumberArr = useMemo(() => {
        let numberArr = []

        for (let i = 0; i < 15; ++i) {
            numberArr[i] = i + 1
        }

        const randomArr = numberArr
            .map(value => ({value, sort: Math.random()}))
            .sort((a, b) => a.sort - b.sort)
            .map(({value}) => value)

        return randomArr
    }, [])

    const renderPrizeBadge = useCallback(
        (idx: number) => {
            const prize = prizeList?.prizes.at(idx)
            if (idx === 0 && prize) {
                return (
                    <div
                        className={
                            'pl-[5px] pr-[8px] py-[4px] bg-[#FFE298] rounded-full flex items-center gap-x-[5px] mr-[10px]'
                        }>
                        <WizImage src={ic_medal_gold} width={15} height={15} />
                        <Text className={`text-ti3 text-[#644D21]`}>
                            {prize} {currency}
                        </Text>
                    </div>
                )
            } else if (idx === 1 && prize) {
                return (
                    <div
                        className={
                            'pl-[5px] pr-[8px] py-[4px] bg-[#C2CACE] rounded-full flex items-center gap-x-[5px] mr-[10px]'
                        }>
                        <WizImage src={ic_medal_silver} width={15} height={15} />
                        <Text className={`text-ti3 text-[#636E8B]`}>
                            {prize} {currency}
                        </Text>
                    </div>
                )
            } else if (idx === 2 && prize) {
                return (
                    <div
                        className={
                            'pl-[5px] pr-[8px] py-[4px] bg-[#AA8B6F] rounded-full flex items-center gap-x-[5px] mr-[10px]'
                        }>
                        <WizImage src={ic_medal_bronze} width={15} height={15} />
                        <Text className={`text-ti3 text-[#5B3E06]`}>
                            {prize} {currency}
                        </Text>
                    </div>
                )
            } else if (prizeList?.prizes?.length === 5 && idx < 5) {
                return (
                    <div
                        className={
                            'pl-[5px] pr-[8px] py-[4px] bg-[#C0C0C0] rounded-full flex items-center gap-x-[5px] mr-[10px]'
                        }>
                        <WizImage src={ic_medal_default} width={15} height={15} />
                        <Text className={`text-ti3 text-[#656565]`}>
                            {prize} {currency}
                        </Text>
                    </div>
                )
            } else if (prizeList?.prizes?.length === 6 && idx < 5) {
                return (
                    <div
                        className={
                            'pl-[5px] pr-[8px] py-[4px] bg-[#C0C0C0] rounded-full flex items-center gap-x-[5px] mr-[10px]'
                        }>
                        <WizImage src={ic_medal_default} width={15} height={15} />
                        <Text className={`text-ti3 text-[#656565]`}>
                            {prize} {currency}
                        </Text>
                    </div>
                )
            } else if (prizeList?.prizes?.length === 6 && idx < 10) {
                return (
                    <div
                        className={
                            'pl-[5px] pr-[8px] py-[4px] bg-[#C0C0C0] rounded-full flex items-center gap-x-[5px] mr-[10px]'
                        }>
                        <WizImage src={ic_medal_default} width={15} height={15} />
                        <Text className={`text-ti3 text-[#656565]`}>
                            {t('event.common.currency.usdt', {prize: prizeList?.prizes?.at(5)})}
                        </Text>
                    </div>
                )
            } else if (isUseComment) {
                return (
                    <div
                        className={
                            'pl-[5px] pr-[8px] py-[4px] bg-white/10 rounded-full flex items-center gap-x-[5px] mr-[10px]'
                        }>
                        <Text className={`text-ti3 text-white]`}>
                            {t(`event.common.rank.comment.others${randomCommentNumberArr.at(idx)}`)}
                        </Text>
                    </div>
                )
            }
        },
        [prizeList, currency, isUseComment, randomCommentNumberArr, t],
    )

    const getRenderRankTextStyle = useCallback(
        idx => {
            const prize = prizeList?.prizes.at(idx)
            if (idx === 0 && prize) {
                return 'text-[#FFCB44]'
            } else if (idx === 1 && prize) {
                return 'text-[#DEDEDE]'
            } else if (idx === 2 && prize) {
                return 'text-[#FF9839]'
            }
        },
        [prizeList],
    )

    return (
        <>
            <div
                className={`pt-[20px] gap-[10px] w-full flex flex-col flex-wrap justify-between sm:gap-0 ${
                    userRankList?.length < 9 ? 'max-h-[350px]' : 'max-h-[480px] '
                } ${
                    userRankList?.length === 0 && `${rank.border} rounded-[5px] mx-auto md:max-w-[545px]`
                } md:max-h-full`}>
                {userRankList?.length === 0 ? (
                    <div className={'text-center pt-[70px] pb-[90px] border border-white/20 rounded-[5px] w-full'}>
                        <NoRankUserIcon className={`w-[40px] mx-auto ${rank.no_rank_fill}`} />
                        <Text className={'pt-[10px] text-[16px] text-gray04 dark:text-dark_gray04 md:text-[14px]'}>
                            {t('event.common.rank.noUser')}
                        </Text>
                        <WizButton
                            text={t('event.common.rank.joinNow')}
                            size={'medium'}
                            className={`${rank.button} mx-auto  mt-[20px]`}
                            onClick={() => onClickJoin()}
                        />
                    </div>
                ) : (
                    userRankList?.map((list, idx) => {
                        return (
                            <div
                                key={list?.id}
                                onClick={() => {
                                    createLog('event', 'event_bitmex_thirteenth_click_user_protfolio', {
                                        user_id: list?.id,
                                    })
                                    goUserPortfolio(list?.id)
                                }}
                                className={`py-[12px] px-[20px] max-w-[545px] lg:max-w-[49.5%] cursor-pointer w-full md:max-w-[545px] sm:px-[10px] sm:py-[10px] md:w-full sm:max-w-[100%] md:mx-auto flex item-center gap-x-[5px] rounded-[5px] ${rank.default}`}>
                                <Text
                                    className={`text-ti1 text-[#BDBDBD] min-w-[24px] text-center flex flex-col justify-center md:text-ti2 ${getRenderRankTextStyle(
                                        idx,
                                    )}`}>
                                    {list?.rank}
                                </Text>
                                <div className={'flex-1 flex gap-x-[10px] items-center truncate'}>
                                    <div className={'relative'}>
                                        <UserThumbnail
                                            src={list?.thumbnail}
                                            size={28}
                                            containerClassName={`border-[2px] ${getRankThumbnailStyle(idx)}`}
                                        />
                                        {idx < 3 && (
                                            <div
                                                className={`content-[''] w-[28px] h-[28px] box-content border-[1px] border-[#1C1C1C] absolute top-[50%] left-[50%] rounded-full translate-x-[-50%] translate-y-[-50%]`}
                                            />
                                        )}
                                    </div>
                                    <Text className={'md:text-body2 truncate sm:w-[40vw]'}>{list?.name}</Text>
                                </div>
                                <div className={'sm:hidden flex items-center'}>
                                    {prizeList?.prizes?.length < 6
                                        ? prizeList?.prizes.length < 4
                                            ? idx < 8 && renderPrizeBadge(idx)
                                            : idx < 10 && renderPrizeBadge(idx)
                                        : renderPrizeBadge(idx)}
                                </div>
                                <Text className={`${rank.profit} md:text-body2 flex items-center sm:pl-[5px]`}>
                                    {displayPercent(list?.score)}
                                </Text>
                            </div>
                        )
                    })
                )}
            </div>
        </>
    )
}

export default React.memo(LeagueRankTable)
