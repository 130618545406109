import React, {useEffect, useRef, useState} from 'react'
import Text from '@component/Text/Text'
import {useTranslation} from 'next-i18next'
import {IEventLeague} from '@api/rank/league/getLeagueDetail'
import {IEventNavigation} from '@api/event/event'

interface IProps {
    className?: string
    eventInitState: IEventLeague
    navigationList?: IEventNavigation[]
    isObserverScroll: boolean
    observeValue: string
    setObserveValue: React.Dispatch<React.SetStateAction<string>>
    renderLeft: string
    navigationClassName?: string
    useMobileDimmed?: boolean
}

const LeagueNavigation = (
    {
        className,
        eventInitState,
        navigationList,
        isObserverScroll,
        observeValue,
        setObserveValue,
        renderLeft,
        navigationClassName,
        useMobileDimmed = true,
    }: IProps,
    ref: any,
) => {
    const {t} = useTranslation()
    const {navigation, common, id} = eventInitState
    const navigationRef = useRef<any>()

    // navigation observer

    const [isView, setIsView] = useState(false)

    useEffect(() => {
        if (window.scrollY > navigationRef?.current?.offsetTop - 50) {
            setTimeout(() => setIsView(true), 1000)
        } else {
            setIsView(true)
        }
    }, [])

    return (
        <div className={`${className} ${navigation.bg} sm:min-h-[64px]`}>
            <div ref={navigationRef} className={`${isObserverScroll && 'h-[70px] md:h-[64px]'}`} />
            <div
                className={`${navigation.bg} ${
                    isObserverScroll
                        ? 'fixed top-[50px] left-0 w-full z-dropdown'
                        : 'relative sm:absolute sm:max-w-[100%]'
                } ${!isView && 'hidden'}`}>
                <div
                    id={'navigation_scrollbar'}
                    className={`max-w-[1100px] w-full mx-auto relative sm:overflow-x-auto sm:scrollbar-hide ${navigation.default}`}>
                    <div className={`flex items-center justify-between`}>
                        {navigationList?.map(link => (
                            <div
                                key={link.observeValue}
                                onClick={() => {
                                    link.onClick()
                                    setObserveValue(link.observeValue as string)
                                }}
                                className={`flex-1 py-[20px] cursor-pointer duration-500 ${navigationClassName} ${
                                    observeValue === link.observeValue && navigation.active
                                }`}>
                                <Text
                                    enablePreWhiteSpace={false}
                                    className={`text-h3 text-center md:text-ti1 md:whitespace-nowrap`}>
                                    {link.text}
                                </Text>
                            </div>
                        ))}
                    </div>
                    <div
                        style={{width: `${100 / navigationList?.length}%`}}
                        className={`h-[2px] duration-[0.8s] ${navigation.bar} absolute bottom-0  ${renderLeft}`}
                    />
                </div>
            </div>
            {useMobileDimmed && navigationList?.length > 3 && observeValue !== navigationList?.at(-1)?.observeValue && (
                <div
                    className={`hidden sm:block h-[70px] md:h-[64px] ${
                        isObserverScroll ? 'top-[50px] sm:fixed sm:z-sticky ' : 'sm:absolute sm:top-0'
                    } sm:right-0 sm:w-[40px] sm:bg-gradient-to-r sm:from-[#2C304D]/0 sm:to-[#2C304D]`}
                />
            )}
        </div>
    )
}

export default React.forwardRef(LeagueNavigation)
