import React, {useMemo} from 'react'
import Text from '@component/Text/Text'
import {useTranslation} from 'next-i18next'
import {IEventPrizePool, ILeague} from '@api/rank/league/league'
import useQueryFetchMe from '@hook/query/account/useQueryFetchMe'
import UserThumbnail from '@component/images/UserThumbnail'
import {useRouter} from 'next/router'
import useFormatNumber from '@hook/useFormatNumber'
import WizButton from '@component/buttons/WizButton'
import useNavigation from '@hook/useNavigation'
import RightArrow from '@svg/event/common/ic_leaderboard_arrow.svg'
import LeagueRankTable from '@feature/event/league/LeagueRankTable'
import LeagueGetRankTimer from '@feature/event/league/LeagueGetRankTimer'
import {IEventLeague} from '@api/rank/league/getLeagueDetail'
import {createLog} from '@util/logs'
import {IUserRank} from '@api/rank/rank'

interface IProps {
    className?: string
    data?: ILeague
    prizeList?: IEventPrizePool
    onClickJoin?: () => void
    eventInitState: IEventLeague
    isUseComment?: boolean
    timerTextClassName?: string
    userRankList?: IUserRank[]
}

const LeagueRankContainer: React.FC<IProps> = ({
    className,
    data,
    prizeList,
    onClickJoin,
    eventInitState,
    isUseComment,
    timerTextClassName,
    userRankList,
}) => {
    const {t} = useTranslation()
    const router = useRouter()
    const {rank, id, common} = eventInitState

    const {displayPercent} = useFormatNumber()
    const {goLeaderboardLeague} = useNavigation()
    const {data: me} = useQueryFetchMe()

    const renderLeagueTimer = useMemo(() => {
        if (data?.start_at * 1000 > new Date().getTime()) {
            return <Text className={`${timerTextClassName}`}>{t('event.common.rank.time.already')}</Text>
        } else if (data?.end_at * 1000 < new Date().getTime()) {
            return <Text className={`${timerTextClassName}`}>{t('event.common.rank.time.closed')}</Text>
        } else {
            return <LeagueGetRankTimer interval={120} className={`text-gray04 ${timerTextClassName}`} />
        }
    }, [data, t, timerTextClassName])

    return (
        <div className={`mt-[90px] md:mt-[60px] px-[20px] md:px-[10px] sm:px-[20px]  ${className}`}>
            <div className={'text-center'}>
                <Text className={`text-h1 md:text-h2`}>{t('event.common.rank.title')}</Text>
                {renderLeagueTimer}
            </div>
            <div
                className={`${
                    me && data?.is_joined && 'mt-[30px]'
                } max-w-[1100px] min-w-[1100px] lg:min-w-[100%] md:min-w-[100%] mx-auto`}>
                {me && data?.is_joined && (
                    <div
                        className={`py-[20px] md:py-[15px] w-full md:max-w-[545px] md:mx-auto sm:max-w-[100%] px-[15px] sm:px-[10px] rounded-[5px] flex items-center gap-x-[5px] ${rank.active.border} ${rank.active.bg}`}>
                        <Text className={'text-ti1 text-[#BDBDBD] min-w-[24px] text-center md:text-ti2'}>
                            {data?.my_league_rank === 0 ? '-' : data?.my_league_rank}
                        </Text>
                        <div className={'flex-1 flex gap-x-[10px] items-center'}>
                            <UserThumbnail src={me?.thumbnail} size={28} />
                            <Text className={'md:text-body2'}>
                                {router?.locale === 'ko' ? me?.nicknames?.at(0)?.nickname ?? me?.name : me?.name}
                            </Text>
                        </div>
                        <Text className={`${rank.profit} md:text-body2`}>{displayPercent(data?.my_league_score)}</Text>
                    </div>
                )}

                <LeagueRankTable
                    prizeList={prizeList}
                    onClickJoin={onClickJoin}
                    eventInitState={eventInitState}
                    isUseComment={isUseComment}
                    userRankList={userRankList}
                />

                {data?.members_count > 0 && (
                    <div className={'mt-[40px] md:mt-[30px]'}>
                        <WizButton
                            text={t('event.common.rank.viewAll')}
                            size={'large'}
                            className={`${rank.button} ${rank?.buttonText} mx-auto w-[270px] md:w-[545px] sm:w-[100%] sm:px-[20px]`}
                            onClick={() => {
                                createLog('event', 'event_bitmex_thirteenth_click_leaderboard')
                                goLeaderboardLeague(id)
                            }}
                            buttonType={'transparent'}
                            renderRightArea={<RightArrow fill={rank?.buttonText ?? common.baseColor} />}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default LeagueRankContainer
