import React from 'react'
import {useTranslation} from 'next-i18next'
import Text from '@component/Text/Text'
import WizBarAccordion from '@component/accordion/WizBarAccordion'
import ArrowDownIcon from '@svg/event/common/ic_event_accordion_arrow_down.svg'
import ArrowUpIcon from '@svg/event/common/ic_event_accordion_arrow_up.svg'
import TagText from '@component/Text/TagText'
import {IEventLeague} from '@api/rank/league/getLeagueDetail'

interface IProps {
    className?: string
    rule?: React.ReactNode
    disclaimer?: React.ReactNode
    eventInitState: IEventLeague
    title?: string
    containerClassName?: string
}

const LeagueRuleAndDisclaimersContainer: React.FC<IProps> = ({
    className,
    rule,
    disclaimer,
    eventInitState,
    title,
    containerClassName,
}) => {
    const {t} = useTranslation()
    const {ruleAndDisclaimers, common} = eventInitState

    return (
        <div className={`pt-[90px] md:pt-[60px] ${ruleAndDisclaimers.bg} ${className}`}>
            <div className={'max-w-[1100px] mx-auto'}>
                <Text className={'text-h1 md:text-h2'}>{title ?? t('event.common.ruleAndDisclaimers.title')}</Text>
                <div className={`pt-[20px]`}>
                    <WizBarAccordion
                        title={t('event.common.ruleAndDisclaimers.rule.title')}
                        accordionClassName={`md:px-[20px] md:py-[15px] ${ruleAndDisclaimers.accordion}`}
                        titleClassName={'text-h2 md:text-ti1 md:text-[18px]'}
                        renderDownArrowIcon={<ArrowDownIcon width={14} fill={'#FFFFFF'} className={'opacity-60'} />}
                        renderUpArrowIcon={<ArrowUpIcon width={14} fill={'#FFFFFF'} className={'opacity-60'} />}
                        className={` md:px-[10px] sm:px-0 md:text-body3`}
                        isOpen={true}>
                        <TagText className={`text-body2 sm:px-[10px] ${containerClassName}`}>{rule}</TagText>
                    </WizBarAccordion>
                    <WizBarAccordion
                        title={t('event.common.ruleAndDisclaimers.disclaimers.title')}
                        containerClassName={'mt-[20px]'}
                        accordionClassName={`md:px-[20px] md:py-[15px] ${ruleAndDisclaimers.accordion}`}
                        titleClassName={'text-h2 md:text-ti1 md:text-[18px]'}
                        renderDownArrowIcon={<ArrowDownIcon width={14} fill={'#FFFFFF'} className={'opacity-60'} />}
                        renderUpArrowIcon={<ArrowUpIcon width={14} fill={'#FFFFFF'} className={'opacity-60'} />}
                        className={`md:px-[10px] `}>
                        <TagText className={`text-body2 sm:px-[10px] ${containerClassName}`}>{disclaimer}</TagText>
                    </WizBarAccordion>
                </div>
            </div>
        </div>
    )
}

export default LeagueRuleAndDisclaimersContainer
