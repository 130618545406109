import React, {useImperativeHandle, useRef} from 'react'
import WizImage from '@component/images/WizImage'
import {useTranslation} from 'next-i18next'
import WizContentModal from '@component/modal/WizContentModal'
import useNavigation from '@hook/useNavigation'
import {StaticImageData} from 'next/image'

type Screen = 'success' | 'failed'

interface IProps {
    className?: string
    title?: string
    exampleImage: StaticImageData
    exampleImageMobile: StaticImageData
}

const LeagueJoinExampleModal = ({className, exampleImage, exampleImageMobile, title}: IProps, ref: any) => {
    const {t} = useTranslation()
    const {} = useNavigation()

    const modalRef = useRef(null)
    useImperativeHandle(ref, () => ({
        show: () => {
            modalRef.current.show()
        },
    }))
    return (
        <WizContentModal title={title} size={'600'} ref={modalRef}>
            <div className={`flex gap-[10px] mt-[10px] md:mt-[20px] ${className}`}>
                <WizImage containerClassName={'md:hidden'} src={exampleImage} />
            </div>
            <WizImage containerClassName={'hidden md:block'} src={exampleImageMobile} />
        </WizContentModal>
    )
}

export default React.forwardRef(LeagueJoinExampleModal)
