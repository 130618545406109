import React, {useState} from 'react'
import {ILeague} from '@api/rank/league/league'
import {useTranslation} from 'next-i18next'
import RequirementsIcon from '@svg/event/common/ic_requirements.svg'
import Text from '@component/Text/Text'
import WizButton from '@component/buttons/WizButton'
import useNavigation from '@hook/useNavigation'
import {IEventLeague} from '@api/rank/league/getLeagueDetail'
import {useIsomorphicLayoutEffect} from 'usehooks-ts'

interface IProps {
    className?: string
    data?: ILeague
    title?: string
    desc?: string
    onClickJoin?: () => void
    eventInitState: IEventLeague
    containerClassName?: string
}

const LeagueRequirementsContainer: React.FC<IProps> = ({
    className,
    data,
    title,
    desc,
    onClickJoin,
    eventInitState,
    containerClassName,
}) => {
    const {t} = useTranslation()
    const {rank, common, id, requirements} = eventInitState
    const [isLeagueJoin, setIsLeagueJoin] = useState(true)

    useIsomorphicLayoutEffect(() => {
        if (data?.end_at * 1000 < new Date().getTime()) {
            setIsLeagueJoin(false)
        }
    }, [data])

    const {goLeaderboardLeague} = useNavigation()
    return (
        <div className={`${containerClassName}`}>
            <div
                className={`mx-auto py-[90px] md:py-[60px] gap-x-[40px] flex items-center md:flex-col ${
                    className || 'max-w-[1100px] md:max-w-[545px] justify-between '
                }`}>
                <div className={'md:text-center max-w-[800px]'}>
                    <div className={'flex items-center gap-x-[10px] md:justify-center'}>
                        <RequirementsIcon stroke={common.baseColor} />
                        <Text className={'text-ti1 text-[18px]'}>{t('event.common.requirements.title')}</Text>
                    </div>
                    <Text className={'pt-[10px] text-h3 font-normal'}>{desc}</Text>
                </div>
                <div>
                    {isLeagueJoin && !data?.is_joined && (
                        <WizButton
                            className={`px-[40px] md:mt-[40px] ${requirements?.button}`}
                            onClick={() => (data?.is_joined ? goLeaderboardLeague(id) : onClickJoin())}
                            text={t('event.common.requirements.joined')}
                        />
                    )}{' '}
                </div>
            </div>
        </div>
    )
}

export default LeagueRequirementsContainer
