import React, {useMemo} from 'react'
import {useTranslation} from 'next-i18next'
import WizImage from '@component/images/WizImage'
import Text from '@component/Text/Text'
import TagText from '@component/Text/TagText'
import {IEventBonus} from '@api/event/event'
import useFormatNumber from '@hook/useFormatNumber'
import {IEventLeague} from '@api/rank/league/getLeagueDetail'
import IconNext from '@svg/common/ic_btn_next.svg'
import {createLog} from '@util/logs'

interface IProps {
    className?: string
    bonusList?: IEventBonus[]
    eventInitState: IEventLeague
    title?: string
    titleClassName?: string
    desc?: string
    descClassName?: string
    containerClassName?: string
    imageClassName?: string
}

const LeagueBonusContainer: React.FC<IProps> = ({
    className,
    bonusList,
    eventInitState,
    title,
    desc,
    titleClassName,
    descClassName,
    containerClassName,
    imageClassName,
}) => {
    const {t} = useTranslation()
    const {bonuses, common} = eventInitState
    const {displayBalance} = useFormatNumber()

    const getBonusesStyle = useMemo(() => {
        if (bonusList?.length > 4) {
            return 'w-full max-w-[340px]'
        } else {
            return 'flex-1'
        }
    }, [bonusList?.length])

    return (
        <div className={`${bonuses.bg} py-[70px] text-center mt-[90px] md:mt-[60px] md:py-[40px] ${className}`}>
            <Text className={`text-h1 md:text-h2 ${titleClassName}`}>{title ?? t('event.common.bonus.title')}</Text>
            <Text className={`pt-[5px] text-[18px] text-gray04 md:text-body1 ${descClassName}`}>
                {desc ?? t('event.common.bonus.desc')}
            </Text>
            <div
                className={`max-w-[1100px] mt-[50px] flex-wrap mx-auto flex justify-center gap-x-[40px] gap-y-[30px] md:max-w-[545px] md:flex-col md:gap-y-[40px] ${containerClassName}`}>
                {bonusList?.map(bonus => {
                    return (
                        <div className={`relative ${bonus.after && 'sm:pt-[20px]'} ${getBonusesStyle}`} key={bonus.id}>
                            <div className={bonus.isClosed && 'opacity-30'}>
                                <div className={`relative ${imageClassName}`}>
                                    <WizImage
                                        src={bonus.icon}
                                        width={50}
                                        height={50}
                                        containerClassName={'mx-auto'}
                                        imageClassName={'mx-auto'}
                                    />
                                    {bonus.after}
                                </div>
                                <Text
                                    enablePreWhiteSpace={false}
                                    className={`mt-[20px] text-ti1 md:mt-[15px] whitespace-nowrap md:text-h4 ${bonuses.title}`}>
                                    {bonus.title}
                                </Text>
                                {bonus.bonus && <Text className={'text-h2 '}>{bonus.bonus}</Text>}
                                <TagText
                                    isHoverStyle={false}
                                    className={`mt-[5px] text-body1 md:text-body2 ${bonuses.desc} `}>
                                    {bonus.desc}
                                </TagText>
                                {bonus.linkText && (
                                    <div className={`mt-[10px] flex items-center justify-center ${bonuses.arrowColor}`}>
                                        <Text
                                            className={`text-body2 cursor-pointer md:py-[8px] ${bonuses.text}`}
                                            onClick={() => {
                                                if (bonus?.onClick) {
                                                    bonus?.onClick()
                                                } else {
                                                    createLog('event', 'event_bitmex_thirteenth_click_bonus_link', {
                                                        link: bonus.link,
                                                    })
                                                    window.open(bonus.link)
                                                }
                                            }}>
                                            {bonus.linkText}
                                        </Text>
                                        <IconNext
                                            fill={!bonuses.arrowColor && common.baseColor}
                                            className={'w-[12px]'}
                                        />
                                    </div>
                                )}
                            </div>
                            {bonus.isClosed && (
                                <div className={'absolute w-full h-full top-0 left-0 '}>
                                    <div
                                        className={`absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] cursor-default rotate-[-30deg] w-[104px] h-[104px] rounded-full ${bonuses.close.circle}`}>
                                        <div
                                            className={`absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] flex items-center w-[88px] h-[88px] rounded-full ${bonuses.close.border}`}>
                                            <TagText className={'text-ti1 text-[18px] text-center flex-1'}>
                                                {t('event.common.bonus.closed')}
                                            </TagText>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default LeagueBonusContainer
