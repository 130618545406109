import React, {useCallback} from 'react'
import {IEventPrizePool} from '@api/rank/league/league'
import Text from '@component/Text/Text'
import {useTranslation} from 'next-i18next'
import prizeIconAnimation from '@animation/event/common/lottie_prize_icon.json'
import {IEventLeague} from '@api/rank/league/getLeagueDetail'
import dynamic from 'next/dynamic'

const Lottie = dynamic(() => import('lottie-react'), {ssr: false})

interface IProps {
    className?: string
    nowPrizePool?: IEventPrizePool
    eventInitState: IEventLeague
    isShowLottie?: boolean
}

const LeaguePrizeDistributionContainer: React.FC<IProps> = ({
    className,
    nowPrizePool,
    eventInitState,
    isShowLottie = true,
}) => {
    const {t} = useTranslation()
    const {currency} = eventInitState
    const renderPrizeBadge = useCallback(
        (idx?: number) => {
            switch (idx) {
                case 0:
                    return (
                        <div className={'text-ti2 text-gray01 px-[15px] bg-[#FFCB44] rounded-full'}>
                            <Text enablePreWhiteSpace={false} className={'whitespace-nowrap'}>
                                {t('event.common.prizeDistribution.1st')}
                            </Text>
                        </div>
                    )
                case 1:
                    return (
                        <div className={'text-ti2 text-gray01 px-[15px] bg-[#DEDEDE] rounded-full'}>
                            <Text enablePreWhiteSpace={false} className={'whitespace-nowrap'}>
                                {t('event.common.prizeDistribution.2nd')}
                            </Text>
                        </div>
                    )
                case 2:
                    return (
                        <div className={'text-ti2 text-gray01 px-[15px] bg-[#BF7758] rounded-full'}>
                            <Text enablePreWhiteSpace={false} className={'whitespace-nowrap'}>
                                {t('event.common.prizeDistribution.3rd')}
                            </Text>
                        </div>
                    )
                case 3:
                    return (
                        <div className={'text-ti2 text-gray04 px-[15px] bg-[#303030] rounded-full'}>
                            <Text enablePreWhiteSpace={false} className={'whitespace-nowrap'}>
                                {t('event.common.prizeDistribution.4th')}
                            </Text>
                        </div>
                    )
                case 4:
                    return (
                        <div className={'text-ti2 text-gray04 px-[15px] bg-[#303030] rounded-full'}>
                            <Text enablePreWhiteSpace={false} className={'whitespace-nowrap'}>
                                {t('event.common.prizeDistribution.5th')}
                            </Text>
                        </div>
                    )
                case 5:
                    return (
                        <div className={'text-ti2 text-gray04 px-[15px] bg-[#303030] rounded-full'}>
                            <Text enablePreWhiteSpace={false} className={'whitespace-nowrap'}>
                                {t('event.common.prizeDistribution.others')}
                            </Text>
                        </div>
                    )
            }
        },
        [t],
    )

    return (
        <div
            className={`text-center mt-[60px] pb-[74px] px-[20px] ${
                isShowLottie ? 'sm:mt-[20px]' : 'sm:mt-[40px]'
            } sm:pb-[66px] ${className}`}>
            <div>
                {isShowLottie && (
                    <Lottie animationData={prizeIconAnimation} className={'flex-none mx-auto max-w-[70px]'} />
                )}
                <Text className={'text-h2 sm:text-h3'}>{t('event.common.prizeDistribution.title')}</Text>
            </div>
            <div
                className={`mt-[20px] ${
                    nowPrizePool.prizes.length === 5
                        ? 'flex justify-center items-center'
                        : 'grid grid-cols-[auto_auto_auto]'
                } flex-wrap  gap-x-[60px] md:gap-x-[20px] gap-y-[20px] max-w-[600px] md:max-w-[380px] sm:max-w-[340px] md:px-[10px] sm:px-0 mx-auto`}>
                {nowPrizePool.prizes.map((item, idx) => (
                    <div key={idx} className={'flex items-center md:flex-col gap-x-[12px] md:gap-y-[12px] sm:flex-1'}>
                        {renderPrizeBadge(idx)}
                        <Text enablePreWhiteSpace={false} className={'whitespace-nowrap'}>
                            {item} {currency}
                        </Text>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default LeaguePrizeDistributionContainer
