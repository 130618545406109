import React, {useCallback, useState} from 'react'
import Text from '@component/Text/Text'
import {useRouter} from 'next/router'
import useFormatDate from '@hook/useFormatDate'
import {useTranslation} from 'next-i18next'
import WizButton from '@component/buttons/WizButton'
import IconNewWindow from '@svg/common/ic_new_window.svg'
import Link from 'next/link'
import {ILeague} from '@api/rank/league/league'
import TagText from '@component/Text/TagText'
import useNavigation from '@hook/useNavigation'
import {IEventLeague} from '@api/rank/league/getLeagueDetail'
import IconCheck from '@svg/common/ic_check.svg'
import {useIsomorphicLayoutEffect} from 'usehooks-ts'
import {createLog} from '@util/logs'
import XImageSource from '@svg/sns/ic_x.svg'
import TelegramImageSource from '@svg/sns/ic_telegram.svg'
import DiscordImageSource from '@svg/sns/ic_discord.svg'

interface IProps {
    className?: string
    data?: ILeague
    title: string | React.ReactNode
    desc?: string
    registerText?: string
    onClickJoin?: () => void
    logo: JSX.Element
    mobileLogo: JSX.Element
    eventInitState: IEventLeague
    containerClassName?: string
    titleClassName?: string
    backgroundAnimation?: React.ReactNode
    startTime?: number
}

const LeagueIntroduceContainer: React.FC<IProps> = ({
    className,
    data,
    title,
    desc,
    registerText,
    onClickJoin,
    logo,
    mobileLogo,
    eventInitState,
    containerClassName,
    titleClassName,
    backgroundAnimation,
    startTime = 12,
}) => {
    const {t} = useTranslation()
    const {common, introduce, id, link} = eventInitState
    const router = useRouter()
    const {displayDate} = useFormatDate()
    const {goLeaderboard} = useNavigation()

    const [isLeagueAlready, setIsLeagueAlready] = useState(false)
    const [isLeagueOverdue, setIsLeagueOverdue] = useState(false)

    const renderDate = useCallback(
        (date, position: 'start' | 'end') => {
            const hours = new Date(date * 1000).getHours()
            const minutes =
                new Date(date * 1000).getMinutes() < 10
                    ? `0${new Date(date * 1000).getMinutes()}`
                    : new Date(date * 1000).getMinutes()
            const timeString =
                hours >= 12
                    ? t('event.common.date.time.pm', {
                          time: hours === 12 ? `${12}` : `${hours - 12}`,
                      })
                    : t('event.common.date.time.am', {time: `${hours}`})

            const normalTimeString = () => {
                if (router?.locale === 'en') {
                    const baseTime = startTime - 9
                    return baseTime >= 12
                        ? t('event.common.date.time.pm', {
                              time: baseTime === 12 ? `${12}` : `${baseTime - 12}`,
                          })
                        : t('event.common.date.time.am', {time: `${baseTime}`})
                } else {
                    return hours >= 12
                        ? t('event.common.date.time.pm', {
                              time: hours === 12 ? `${12}` : `${hours - 12}`,
                          })
                        : t('event.common.date.time.am', {time: `${hours}`})
                }
            }

            return (
                date &&
                `${displayDate(
                    new Date(date).getTime(),
                    position === 'start'
                        ? router?.locale === 'en'
                            ? t('event.common.dateFormat.date.month')
                            : t('event.common.dateFormat.date')
                        : router?.locale === 'en'
                        ? t('event.common.dateFormat.date')
                        : t('event.common.dateFormat.date.month'),
                )} ${position === 'end' ? (startTime ? normalTimeString() : timeString) : ''}`
            )
        },
        [router?.locale, t, startTime, displayDate],
    )

    useIsomorphicLayoutEffect(() => {
        if (data?.start_at * 1000 > new Date().getTime()) {
            setIsLeagueAlready(true)
        } else if (new Date().getTime() > data?.end_at * 1000) {
            setIsLeagueOverdue(true)
        }
    }, [data])

    const onClickSns = (sns: 'twitter' | 'telegram' | 'facebook' | 'instagram') => {
        createLog('event', `event_bitmex_thirteenth_league_click_sns`, {sns})
    }

    return (
        <div className={` ${common?.subBgColor && common?.subBgColor}`}>
            <div className={'absolute top-0 left-0 w-full h-full max-h-[690px]'}>
                {backgroundAnimation}
                <div
                    className={`${introduce.bgImage} w-full absolute top-0 bg-center left-[50%] h-full max-h-[690px] translate-x-[-50%] z-[2] ${introduce.bg}`}></div>
                <div
                    style={{background: introduce.shadow}}
                    className={`w-full absolute top-0 left-0 h-full max-h-[690px] z-[3]`}
                />
            </div>
            <div className={`relative z-[4] ${className}`}>
                <div
                    className={`relative max-w-[1100px] mx-auto pt-[120px] text-center sm:max-w-[100%] sm:px-[20px] ${containerClassName}`}>
                    <div>
                        <div className={'sm:hidden'}>{logo}</div>
                        <div className={'hidden sm:block'}>{mobileLogo}</div>
                    </div>
                    {typeof title === 'string' ? (
                        <TagText
                            className={`text-h1 mx-auto mt-[10px] sm:text-h2 ${titleClassName} ${
                                introduce?.titleColor && introduce?.titleColor
                            }`}>
                            {title}
                        </TagText>
                    ) : (
                        <div>{title}</div>
                    )}
                    <Text className={'mt-[20px] text-h4 sm:text-ti2'}>
                        {t('event.common.date.format', {
                            start: renderDate(data?.start_at, 'start') ?? '-',
                            end: renderDate(data?.end_at, 'end') ?? '-',
                        })}
                    </Text>
                    <TagText className={`mt-[10px] text-body1 sm:text-body2 md:px-[10px] ${common.descColor}`}>
                        {desc}
                    </TagText>
                    <div
                        className={
                            'flex gap-x-[15px] justify-center items-center mt-[40px] sm:flex-col sm:gap-y-[10px]'
                        }>
                        <div className={'max-w-[270px] sm:max-w-[100%] sm:w-full'}>
                            <WizButton
                                className={`flex-1 w-[270px] h-[55px] sm:w-[100%] ${
                                    data?.is_joined ? common.isJoinMainColor : common.mainColor
                                }`}
                                textClassName={`text-btn ${introduce?.joinButtonText || ''}`}
                                size={'large'}
                                onClick={() => {
                                    if (isLeagueOverdue) {
                                        goLeaderboard('league')
                                    } else {
                                        onClickJoin()
                                    }
                                }}
                                buttonType={'transparent'}
                                renderLeftArea={
                                    data?.is_joined &&
                                    !isLeagueOverdue && (
                                        <IconCheck
                                            className={`w-[14px] ${introduce?.joinButtonText}`}
                                            fill={data?.is_joined ? common.isJoinMainColor : common.mainColor}
                                        />
                                    )
                                }
                                text={
                                    isLeagueOverdue
                                        ? t('event.common.introduce.homepage')
                                        : data?.is_joined
                                        ? t('event.common.introduce.joined')
                                        : t('event.common.introduce.joinNow')
                                }
                            />
                        </div>
                        <WizButton
                            className={`flex-1 max-w-[270px] h-[55px] sm:w-[100%] sm:max-w-[100%] !text-white ${introduce.register}`}
                            textClassName={'text-btn'}
                            buttonType={'transparent'}
                            size={'large'}
                            onClick={() => {
                                createLog('event', 'bitmex_thirteenth_league_register_bitmex')
                                window.open(link.register)
                            }}
                            text={registerText}
                            renderRightArea={<IconNewWindow fill={common.baseColor} width={16} />}
                        />
                    </div>
                    <div className={'flex items-center gap-x-[15px] justify-center mt-[30px]'}>
                        <Link href={t('sns.twitter.url')} target={'_blank'}>
                            <XImageSource width={36} height={36} />
                        </Link>
                        <Link href={t('sns.telegram.url')} target={'_blank'}>
                            <TelegramImageSource width={36} height={36} />
                        </Link>
                        <Link href={t('sns.discord.url')} target={'_blank'}>
                            <DiscordImageSource width={36} height={36} />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LeagueIntroduceContainer
