import React, {useMemo} from 'react'
import {useTranslation} from 'next-i18next'
import WizImage from '@component/images/WizImage'
import Text from '@component/Text/Text'
import TagText from '@component/Text/TagText'
import {IEventBonus} from '@api/event/event'
import useFormatNumber from '@hook/useFormatNumber'
import {IEventLeague} from '@api/rank/league/getLeagueDetail'
import IconNext from '@svg/common/ic_thin_arrow.svg'
import {createLog} from '@util/logs'
import WizButton from '@component/buttons/WizButton'
import IconNewWindow from '@svg/common/ic_new_window.svg'
import Link from 'next/link'

interface IProps {
    className?: string
    bonusList?: IEventBonus[]
    eventInitState: IEventLeague
    title?: string
    titleClassName?: string
    desc?: string
    descClassName?: string
    containerClassName?: string
    imageClassName?: string
    button?: string
    buttonClassName?: string
    buttonTextClassName?: string
    link?: string
}

const LeagueBonusContainerVer02: React.FC<IProps> = ({
    className,
    bonusList,
    eventInitState,
    title,
    desc,
    titleClassName,
    descClassName,
    containerClassName,
    imageClassName,
    button,
    buttonClassName,
    buttonTextClassName,
    link,
}) => {
    const {t} = useTranslation()
    const {bonuses, common} = eventInitState
    const {displayBalance} = useFormatNumber()

    const getBonusesStyle = useMemo(() => {
        if (bonusList?.length > 4) {
            return 'w-full max-w-[340px]'
        } else {
            return 'flex-1'
        }
    }, [bonusList?.length])

    return (
        <div className={`${bonuses.bg} py-[70px] text-center mt-[90px] md:mt-[60px] md:py-[40px] ${className}`}>
            <Text className={`text-h1 md:text-h2 ${titleClassName}`}>{title ?? t('event.common.bonus.title')}</Text>
            <Text className={`pt-[5px] text-[18px] text-gray04 md:text-body1 ${descClassName}`}>
                {desc ?? t('event.common.bonus.desc')}
            </Text>
            <div
                className={`max-w-[1100px] mt-[50px] mx-auto flex justify-center gap-x-[20px] gap-y-[30px] md:max-w-[545px] md:flex-col md:gap-y-[40px] ${containerClassName}`}>
                {bonusList?.map((bonus, idx) => {
                    return (
                        <div key={bonus.id} className={'flex flex-1 gap-x-[20px] items-center'}>
                            <div className={`h-full relative ${bonus.after && 'sm:pt-[20px]'} ${getBonusesStyle}`}>
                                <div
                                    className={`h-full flex flex-col justify-between ${
                                        bonus.isClosed && 'opacity-30'
                                    }`}>
                                    <div className={''}>
                                        <div className={`relative ${imageClassName}`}>
                                            <WizImage
                                                src={bonus.icon}
                                                width={50}
                                                height={50}
                                                containerClassName={'mx-auto'}
                                                imageClassName={'mx-auto'}
                                            />
                                            {bonus.after}
                                        </div>
                                        <Text
                                            enablePreWhiteSpace={false}
                                            className={`mt-[20px] text-ti1 md:mt-[15px] whitespace-nowrap md:text-h4 ${bonuses.title}`}>
                                            {bonus.title}
                                        </Text>
                                        {bonus.bonus && <Text className={'text-h2 '}>{bonus.bonus}</Text>}
                                        <TagText
                                            isHoverStyle={false}
                                            className={`mt-[5px] text-body1 md:text-body2 ${bonuses.desc} `}>
                                            {bonus.desc}
                                        </TagText>
                                    </div>
                                    {bonus?.links ? (
                                        <div className={'mt-[15px] space-y-[7px]'}>
                                            {bonus.links?.map((item, idx) => (
                                                <div
                                                    key={idx}
                                                    className={`flex items-center justify-center ${bonuses.arrowColor}`}>
                                                    <Text
                                                        className={`text-body2 underline cursor-pointer ${bonuses.text}`}
                                                        onClick={() => {
                                                            if (item?.onClick) {
                                                                item?.onClick()
                                                            } else {
                                                                createLog(
                                                                    'event',
                                                                    'event_bitmex_thirteenth_click_bonus_link',
                                                                    {
                                                                        link: item.link,
                                                                    },
                                                                )
                                                                window.open(item.link)
                                                            }
                                                        }}>
                                                        {item.linkText}
                                                    </Text>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        bonus.linkText && (
                                            <div
                                                className={`mt-[10px] flex items-center justify-center ${bonuses.arrowColor}`}>
                                                <Text
                                                    className={`text-body2 underline cursor-pointer md:py-[8px] ${bonuses.text}`}
                                                    onClick={() => {
                                                        if (bonus?.onClick) {
                                                            bonus?.onClick()
                                                        } else {
                                                            createLog(
                                                                'event',
                                                                'event_bitmex_thirteenth_click_bonus_link',
                                                                {
                                                                    link: bonus.link,
                                                                },
                                                            )
                                                            window.open(bonus.link)
                                                        }
                                                    }}>
                                                    {bonus.linkText}
                                                </Text>
                                            </div>
                                        )
                                    )}
                                </div>
                                {bonus.isClosed && (
                                    <div className={'absolute w-full h-full top-0 left-0 '}>
                                        <div
                                            className={`absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] cursor-default rotate-[-30deg] w-[104px] h-[104px] rounded-full ${bonuses.close.circle}`}>
                                            <div
                                                className={`absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] flex items-center w-[88px] h-[88px] rounded-full ${bonuses.close.border}`}>
                                                <TagText className={'text-ti1 text-[18px] text-center flex-1'}>
                                                    {t('event.common.bonus.closed')}
                                                </TagText>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <IconNext
                                fill={bonus?.arrowColor ?? '#707683'}
                                className={`w-[30px] md:hidden ${bonusList?.length - 1 === idx && 'hidden'}`}
                            />
                        </div>
                    )
                })}
            </div>
            <div className={'mt-[50px]'}>
                <Link href={link} target={'_blank'} className={'max-w-[270px] w-full sm:max-w-[100%] block mx-auto'}>
                    <WizButton
                        className={`flex-1 w-full h-[55px] !text-white ${buttonClassName}`}
                        textClassName={`text-btn ${buttonTextClassName}`}
                        size={'large'}
                        text={button}
                        renderRightArea={
                            <IconNewWindow fill={common.baseColor} width={15} className={` ${buttonTextClassName}`} />
                        }
                        isLink={!!link}
                    />
                </Link>
            </div>
        </div>
    )
}

export default LeagueBonusContainerVer02
