import React from 'react'
import Text from '@component/Text/Text'

interface IProps {
    className?: string
    icon?: any
    title?: string
    titleClassName?: string
    descClassName?: string
    desc?: React.ReactNode
}

const LeagueDescriptionContainer: React.FC<IProps> = ({
    className,
    icon,
    titleClassName,
    title,
    descClassName,
    desc,
}) => {
    return (
        <div className={`text-center py-[70px] ${className}`}>
            <div className={'flex justify-center'}>{icon}</div>
            <div className={`pt-[10px]`}>
                <Text className={`text-[30px] leading-[36px] font-bold sm:text-h4 ${titleClassName}`}>{title}</Text>
            </div>
            <div className={`pt-[10px] ${descClassName}`}>{desc}</div>
        </div>
    )
}

export default LeagueDescriptionContainer
