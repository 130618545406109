import React from 'react'
import {useTranslation} from 'next-i18next'
import useNavigation from '@hook/useNavigation'
import Text from '@component/Text/Text'
import WizButton from '@component/buttons/WizButton'
import {IEventJoin} from '@api/event/event'
import Span from '@component/Text/Span'
import {IEventLeague} from '@api/rank/league/getLeagueDetail'
import TagSpan from '@component/Text/TagSpan'

interface IProps {
    className?: string
    howToList?: IEventJoin[]
    eventInitState: IEventLeague
}

const LeagueHowToJoinContainer: React.FC<IProps> = ({className, howToList, eventInitState}) => {
    const {t} = useTranslation()
    const {howto, common, id} = eventInitState

    const {} = useNavigation()

    return (
        <div className={`${howto.bg} pt-[70px] md:pt-[40px] ${className} `}>
            <div className={'mx-auto max-w-[1100px] md:px-[10px] sm:px-0'}>
                <Text className={'text-h1 md:text-h2'}>{t('event.common.howToJoin.title')}</Text>
                <div className={`mt-[20px] border-b ${howto.line}`}>
                    {howToList?.map((list, idx) => (
                        <div
                            className={`flex py-[30px] gap-x-[20px] border-t md:flex-col ${
                                !list?.desc && 'items-center md:items-start'
                            } ${howto.line}`}
                            key={list.id}>
                            <div className={'w-[64px]'}>
                                <Text className={`text-ti1 !text-[18px] sm:!text-[16px] ${howto.step}`}>
                                    {t(`event.common.howToJoin.step${idx + 1}`)}
                                </Text>
                            </div>
                            <div className={`flex-1 pr-[40px] space-y-[10px] md:space-y-0 md:mt-[10px] md:pr-0`}>
                                <TagSpan className={'text-ti1 !text-[18px] sm:!text-[16px]'}>{list.text}</TagSpan>
                                <Span
                                    className={`pl-[10px] text-body2 md:block md:pl-0 md:pt-[5px] md:pb-[10px] ${howto.desc}`}>
                                    {list.subText}
                                </Span>
                                {list.desc}
                            </div>

                            <div className={'my-auto md:mt-[20px]'}>
                                <WizButton
                                    onClick={() => {
                                        list.onClick && list.onClick()
                                    }}
                                    textClassName={'text-body2'}
                                    text={list.button}
                                    className={`w-[170px] md:hidden ${howto.button}`}
                                />
                                <WizButton
                                    onClick={() => {
                                        list.mobileOnClick ? list.mobileOnClick() : list.onClick()
                                    }}
                                    text={list.button}
                                    textClassName={'text-body3 sm:text-body2'}
                                    className={`w-[170px] hidden md:block ${howto.button}`}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default LeagueHowToJoinContainer
