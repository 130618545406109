import React, {useEffect, useState} from 'react'
import Text from '@component/Text/Text'
import {useTranslation} from 'next-i18next'

interface IProps {
    className?: string
    interval: number
}

const LeagueGetRankTimer: React.FC<IProps> = ({className, interval = 120}) => {
    const {t} = useTranslation()
    const [seconds, setSeconds] = useState(120)

    useEffect(() => {
        const countdown = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1)
            }
            if (seconds === 0) {
                setSeconds(interval)
            }
        }, 1000)
        return () => clearInterval(countdown)
    }, [seconds, interval])

    return (
        <>
            <Text className={`pt-[5px] text-h4 font-normal ${className}`}>
                {t('event.common.rank.time.update', {time: seconds})}
            </Text>
        </>
    )
}

export default LeagueGetRankTimer
