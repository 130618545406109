import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'next-i18next'
import Text from '@component/Text/Text'
import TagText from '@component/Text/TagText'
import {IEventPrizePool, ILeague} from '@api/rank/league/league'
import useDidMountEffect from '@hook/UseDidMountEffect'
import useResizeObserver from 'use-resize-observer'
import {IEventLeague} from '@api/rank/league/getLeagueDetail'
import useWindowSize from '@hook/useWindowSize'

interface IProps {
    className?: string
    prizeList?: IEventPrizePool[]
    data?: ILeague
    nowPrizePool?: IEventPrizePool
    nextPrizePool?: IEventPrizePool
    eventInitState: IEventLeague
    isRenderBar?: boolean
    isHideTitle?: boolean
    renderCurrency?: string
}

const LeaguePrizePoolContainer: React.FC<IProps> = ({
    className,
    prizeList,
    data,
    nowPrizePool,
    nextPrizePool,
    eventInitState,
    isRenderBar = true,
    isHideTitle,
    renderCurrency,
}) => {
    const {t} = useTranslation()
    const {prizePool, currency} = eventInitState
    const [charge, setCharge] = useState(0)
    const [activePrize, setActivePrize] = useState('0')
    const [checkCount, setCheckCount] = useState(-1)
    const [activeNum, setActiveNum] = useState(0)

    const {ref, width} = useResizeObserver()
    const {isSm} = useWindowSize()

    const renderPriceData = useCallback(
        item => {
            if (activePrize === item?.prize) {
                return `text-white`
            } else {
                return prizePool.text.default
            }
        },
        [activePrize, prizePool.text.default],
    )

    const isRenderActive = useCallback(
        item => {
            if (activePrize === item?.prize) {
                return true
            } else {
                return false
            }
        },
        [activePrize],
    )

    useDidMountEffect(() => {
        for (let i = 0; i < prizeList?.length; i++) {
            setTimeout(() => {
                setCheckCount(i)
                if (nowPrizePool.prize === prizeList?.at(i).prize) {
                    setActivePrize(prizeList?.at(i).prize)
                }
            }, i * ((17 + i) / activeNum) * 100)
            const curr = prizeList?.slice(1)[i]

            if (curr?.min > data?.members_count) {
                break
            }
        }
    }, [activeNum])

    useDidMountEffect(() => {
        if (data) {
            let count = 0
            let prevMin = 0
            let nextMin = 0
            let member = data?.members_count

            for (let i = 0; i < prizeList.slice(1).length; i++) {
                const curr = prizeList.slice(1)[i]
                nextMin = curr?.min
                if (curr?.min > member) {
                    setActiveNum(i)
                    break
                } else if (prizeList?.at(-1).min <= member) {
                    setActiveNum(i)
                }
                prevMin = curr?.min
                count++
            }

            const allLineWidth = () => {
                if (width >= 840) {
                    return 780
                } else if (width >= 520) {
                    return 520
                } else {
                    return width
                }
            }
            const oneItemWidth = allLineWidth() / prizeList.slice(1).length
            const prizeDiff = nextMin - prevMin

            const remainder = member - prevMin

            if (remainder === 0) {
                if (prizeDiff === 0) {
                    setCharge(allLineWidth())
                } else if (width >= 840) {
                    setCharge((count - 1) * (oneItemWidth + 6))
                } else if (width >= 520) {
                    setCharge((count - 1) * (oneItemWidth + 5))
                } else {
                    setCharge((count - 1) * oneItemWidth + (count + 6))
                }
            } else {
                if (prizeDiff === 0) {
                    setCharge(allLineWidth())
                } else if (count === 1) {
                    setCharge(7 + (oneItemWidth * remainder) / prizeDiff)
                } else {
                    if (width >= 840) {
                        setCharge((count - 1) * (oneItemWidth + 2 + count / 2) + oneItemWidth * (remainder / prizeDiff))
                    } else if (width >= 520) {
                        setCharge((count - 1) * oneItemWidth + count * 4.5 + (oneItemWidth * remainder) / prizeDiff)
                    } else {
                        setCharge((count - 1) * oneItemWidth + count * 2.5 + (oneItemWidth * remainder) / prizeDiff)
                    }
                }
            }
        }
    }, [data, width])

    const renderPrizePoolMessage = useMemo(() => {
        if (data) {
            if (!nowPrizePool?.max) {
                return (
                    <TagText className={'text-ti1 text-[18px] md:text-ti2'}>
                        {t('event.common.prizePool.fullMember')}
                    </TagText>
                )
            }
            if (nowPrizePool?.min <= data?.members_count) {
                return (
                    <TagText className={'text-body1 text-[18px] md:text-body2'}>
                        {t('event.common.prizePool.prizeUp', {
                            member: nowPrizePool.max - data?.members_count + 1,
                            prize: nextPrizePool?.prize,
                            currency: currency,
                        })}
                    </TagText>
                )
            }
        }
    }, [data, nowPrizePool, currency, nextPrizePool?.prize, t])
    return (
        <div
            ref={ref}
            style={{backgroundImage: `${prizePool.borderClass}`}}
            className={`max-w-[920px] md:max-w-[560px] sm:max-w-[100%] p-[1px] relative mx-auto mt-[60px] rounded-[10px] sm:mx-[20px] sm:mt-[50px] z-[5] ${prizePool.shadow} ${className}`}>
            <div
                className={`rounded-[10px] px-[40px] pt-[40px] pb-[45px] ${prizePool.background}  ${
                    isRenderBar ? 'md:pt-[35px] md:pb-[75px] md:px-[20px]' : 'md:pt-[35px] md:pb-[35px] md:px-0'
                }`}>
                <Text
                    className={`text-[30px] font-bold leading-[36px] text-center md:text-h4 ${
                        isHideTitle && 'hidden'
                    }`}>
                    {t('event.common.prizePool.title', {
                        prize: t(`event.common.currency.${renderCurrency ?? 'usdt'}`, {prize: prizeList?.at(-1).prize}),
                    })}
                </Text>
                <div
                    className={`${
                        !isHideTitle ? 'mt-[20px] pt-[25px] pb-[20px] md:mt-[15px]' : 'py-[20px] md:py-0'
                    }  rounded-[5px] flex ${prizePool.dataBox}`}>
                    <div className={`flex-1 text-center ${prizePool.border}`}>
                        <Text className={`${prizePool.text.data} md:text-body3 sm:hidden`}>
                            {t('event.common.prizePool.currentPool')}
                        </Text>
                        <Text className={`${prizePool.text.data} hidden text-body3 sm:block`}>
                            {t('event.common.prizePool.currentPool.mo', {currency: currency})}
                        </Text>
                        <Text className={`text-h1 md:text-h2 sm:hidden ${prizePool.text.value}`}>
                            {nowPrizePool.prize} {currency}
                        </Text>
                        <Text className={`hidden sm:block sm:text-h2 ${prizePool.text.value}`}>
                            {nowPrizePool.prize}
                        </Text>
                    </div>
                    <div className={'flex-1 text-center'}>
                        <Text className={`${prizePool.text.data} md:text-body3`}>
                            {t('event.common.prizePool.participants')}
                        </Text>
                        <Text className={`text-h1 md:text-h2 ${prizePool.text.value}`}>{data?.members_count}</Text>
                    </div>
                </div>

                {isRenderBar && (
                    <>
                        <div className={'mt-[30px] text-center'}>{renderPrizePoolMessage}</div>

                        <div className={'mt-[30px] sm:mt-[14px]'}>
                            <div className={'relative'}>
                                <div className={'mx-[30px] md:mx-auto'}>
                                    <div
                                        className={`w-full h-[4px] md:h-[8px] rounded-[2px] md:rounded-full overflow-hidden relative ${prizePool.bar.default}`}>
                                        <div
                                            style={{width: `calc(${charge}px)`}}
                                            className={`${prizePool.bar.charge} h-full duration-[2.5s] overflow-hidden ease-linear absolute top-0 left-0`}></div>
                                    </div>
                                </div>
                                <div
                                    id={'event_progress'}
                                    className={
                                        'flex items-center justify-between mt-[17px] gap-x-[15px] md:max-w-[80%] md:mx-auto md:mt-[0px] md:absolute md:left-[50%] md:top-[8px] md:translate-x-[-50%] md:w-full'
                                    }>
                                    {prizeList.slice(1).map((item, idx) => (
                                        <div className={'relative md:first:hidden md:last:hidden'} key={item?.prize}>
                                            {isRenderActive(item) ? (
                                                <div
                                                    className={`flex justify-center items-center absolute top-[-50%] md:top-[-4px] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[26px] h-[26px] md:w-[16px] md:h-[16px] rounded-full ${prizePool.dot.active.circle}`}>
                                                    <div
                                                        className={`w-[14px] h-[14px] md:w-[8px] md:h-[8px] rounded-full ${prizePool.dot.active.charge} `}
                                                    />
                                                </div>
                                            ) : (
                                                <>
                                                    <div
                                                        className={`flex justify-center items-center absolute top-[-50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[16px] h-[16px] md:hidden rounded-full ${
                                                            idx < checkCount
                                                                ? `${prizePool.dot.active.charge}`
                                                                : `${prizePool.dot.default.charge}`
                                                        }`}>
                                                        <div
                                                            className={`w-[8px] h-[8px] rounded-full ${
                                                                idx < checkCount
                                                                    ? prizePool.dot.active.circle
                                                                    : prizePool.dot.default.circle
                                                            }`}
                                                        />
                                                    </div>
                                                    <div
                                                        className={`justify-center items-center absolute top-[-4px] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[4px] h-[4px] hidden md:flex rounded-full ${
                                                            idx < checkCount
                                                                ? prizePool.dot.active.circle
                                                                : prizePool.dot.default.circle
                                                        }`}
                                                    />
                                                </>
                                            )}

                                            <div
                                                className={`flex-1 text-center ${
                                                    activePrize !== item?.prize && 'md:hidden'
                                                } ${
                                                    activePrize === item?.prize &&
                                                    'md:top-[10px] md:absolute md:left-[-32px] md:w-[66px]'
                                                } progress_point`}>
                                                <Text className={`text-body3 ${renderPriceData(item)}`}>
                                                    {item?.min}
                                                </Text>
                                                <Text
                                                    enablePreWhiteSpace={false}
                                                    className={`text-body3 whitespace-nowrap ${renderPriceData(item)}`}>
                                                    {t('event.common.prizePool.progress.currency.usdt', {
                                                        prize: item?.prize,
                                                    })}
                                                </Text>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default LeaguePrizePoolContainer
